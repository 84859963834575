import React, { useState } from "react";
import { ISendMessagesInterface } from "./SendMessages.interface";
import { EMessageType } from "../../../Interfaces/generalInterfaces";

import { ArrowIcon2, SmallBellIcon } from "../../../Assets";
import TextareaAutosize from "react-textarea-autosize";
import toast from "react-hot-toast";
import NetworkManager from "../../../NetworkManager";

import { useAuth } from "../../../Auth/Auth";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";

export const SendMessages: React.FC<ISendMessagesInterface> = ({
  messageTrigger,
  showBuzzModal,
  accountId,
  isShowBuzzModal,
}) => {
  const [placeHolder, setPlaceHoder] = useState<string>("");
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const handleClick = () => {
    fileInputRef.current!.click();
  };

  const [isUploading, setIsUploading] = React.useState(false);

  const handleFileChange = async (event: any): Promise<boolean> => {
    const file = event.target.files[0];
    setIsUploading(true);

    if (!file) {
      toast.error("Please upload file");
      Promise.resolve(false);
    }

    const response = await NetworkManager.supabaseUpload("public", file);

    if (!response.success) {
      toast.error(response.message || "Unhandled error saving new menu");
      return false;
    }
    const fileUrl =
      window._env_.DOMAIN +
      `/storage/v1/object/public/` +
      response.payload.fullPath;
    messageTrigger(fileUrl, EMessageType.IMAGE);
    setPlaceHoder("");
    setIsUploading(false);

    return true;
  };

  const [chatMessage, setChatMessage] = useState<string>("");
  const sendChatMessageHandler = (chatMessage: string) => {
    messageTrigger(chatMessage, EMessageType.CHAT);
    setChatMessage("");
  };

  const handleFocus = (event) => {
    event.target.blur();
  };

  return (
    <React.Fragment>
      <div className="fixed left-0 bottom-0 w-full bg-white z-40 border-t-[0.5px] border-slate-300">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 sticky">
          <button
            onClick={handleFocus}
            className=" opacity-0 absolute -left-40"
          >
            Remove focus
          </button>
          <div className="flex h-auto py-3 items-center mx-auto">
            <TextareaAutosize
              className="h-[45px] flex-grow border-none outline-none px-4 py-2 mr-4 rounded-xl text-base font-medium bg-white"
              minRows={1}
              maxRows={3}
              placeholder="Write a message"
              autoFocus={false}
              value={chatMessage}
              onChange={(e) => setChatMessage(e.target.value)}
            />
            {chatMessage.length <= 0 && (
              <>
                <Tooltip arrow placement="top" title="Attach a file">
                  <label htmlFor="messenger-upload-file">
                    <IconButton
                      sx={{ mx: 1 }}
                      color="primary"
                      component="span"
                      onClick={handleClick}
                    >
                      <AttachFileTwoToneIcon fontSize="small" />
                    </IconButton>
                  </label>
                </Tooltip>

                <input
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) => handleFileChange(e)}
                  ref={fileInputRef}
                  hidden={true}
                />
              </>
            )}

            {chatMessage.length > 0 && (
              <div
                className="py-2 pr-3 pl-4 rounded-md bg-7m-bg-main"
                onClick={() => sendChatMessageHandler(chatMessage)}
              >
                <ArrowIcon2 className="h-8 w-8 pl-1" fill="white" />
              </div>
            )}
          </div>
        </div>
        {isUploading && <LinearProgress />}
      </div>
    </React.Fragment>
  );
};
