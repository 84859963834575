import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";
import { ISurveyData } from "../Auth/Interfaces";

export interface IChatMessage {
  message: string;
  userName: string;
  date: string;
}

export interface ITable {
  _id: string;
  _uuid: string;
  tableId: string;
  dynamicCode: string;
  description: string;
  waiter: string;
  channelId: string;
  messages?: IChatMessage[];
  channel: PrivateChannel;
  deleted: boolean;
}

export interface IRestaurantViewTable
  extends
    Pick<ITable, "tableId" | "channelId" | "_id" | "_uuid" | "dynamicCode"> {
  messages: IMessage[];
  reactions: IObject<IMessage>;
  channel: PrivateChannel;
  oldestMessageTS: number | null;
  pendingMessages: number | null;
}

export interface IKeyString {
  [key: string]: string;
}
export interface IObject<T> {
  [key: string]: T;
}

// New interfaces
export enum EMessageType {
  CHAT = "CHAT",
  CONFIRMATION = "CONFIRMATION",
  BUZZ = "BUZZ",
  SURVEY = "SURVEY",
  IMAGE = "IMAGE",
  ORDER = "ORDER",
}

export interface IMessage {
  id: string;
  message: string;
  originalMessage?: string;
  username: string;
  timestamp: number;
  messageType: EMessageType;
}

export interface IMenu {
  name: string;
  url: string;
  type?: string;
}

export enum EBottomModalContent {
  QR = "QR",
  BUZZ = "BUZZ",
  MENU = "MENU",
  LANG = "LANG",
}

export interface IUploadResponse {
  id: string;
  path: string;
  fullPath: string;
}

export enum EScreenMode {
  HOME = "HOME",
  HISTORY = "HISTORY",
  MENU = "MENU",
  DISMISS = "DISMISS",
  BACK = "BACK",
}
export enum ResourceType {
  BUZZ = "buzz",
  TABLE = "table",
}
export interface RestaurantLanguage {
  available: Array<ILangOption>;
  isTranslate: boolean;
  targetLang: string;
}
export interface ILangOption {
  value: LanguageSetting;
  label: string;
}

export interface IRestaurantInfo {
  name: string;
  menuInfo: IMenu[];
  buzzList: BuzzItem[];
  surveyData?: ISurveyData;
  welcomeMessage?: string;
  language?: RestaurantLanguage;
  type?: string;
}

export interface IHandshakeResponse {
  _id: string;
  channelId: string;
  dynamicCode: string;
  tableId: string;
  restaurantId: string;
  restaurantInfo: IRestaurantInfo;
  description?: string;
}

export interface IKeyMessage {
  [key: string]: IMessage;
}

export interface IChannelWithEvents {
  [key: string]: {
    [key: string]: IMessage[];
  };
}

export type TMediaQueryBreakpoints = "xs" | "sm" | "md" | "lg" | "xl";
export type TSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";
export type TWeight =
  | "thin"
  | "extralight"
  | "light"
  | "normal"
  | "medium"
  | "semibold"
  | "bold"
  | "extrabold"
  | "black";
export type TLetterSpacing =
  | "tigher"
  | "tight"
  | "normal"
  | "wide"
  | "wider"
  | "widest";
export type TWordBreak = "normal" | "words" | "all";

export interface IChangeRestaurantInfo {
  tableMongoId: string;
  newChannelId: string;
}

export const Languages = ["en", "km", "zh-CN"] as const;
export type LanguageSetting = (typeof Languages)[number];
export interface BuzzItem {
  km: string;
  en: string;
  "zh-CN": string;
  icon?: string;
}

export interface ICategory {
  id: number;
  name: string;
}
export interface EntityMapper {
  uuid: string;
  entity: string;
}
export interface IProduct {
  id: string;
  accountId?: number;
  categories?: Array<ICategory>;
  name: string;
  description?: string;
  price?: number;
  image_url?: string;
}

export interface IPopularProduct {
  popularity: number;
  product_id: string;
  products: IProduct;
}

export interface ICartItem {
  id: string;
  product: IProduct;
  value: number;
  remark?: string;
  variants?: [];
}
export interface IBanner {
  id: string;
  image: string;
  text: string;
  title: string;
}
export interface IAccount {
  id: string;
  name: string;
  active: boolean;
  is_inclusive?: boolean;
  phone_number?: string;
  tax_rate?: number;
  type?: string;
  address?: string;
  resources?: Array<IResource>;
  banners?: Array<IBanner>;
}
export interface IBuzz {
  id: string;
  name: string;
  icon?: string;
}

export interface IResource {
  id: string;
  name: string;
}
