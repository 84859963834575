import {
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";
import Pusher from "pusher-js";
import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";

// Utils
import NetworkManager from "../NetworkManager";

// Components
import { TopMenu } from "../User/TopMenu";

// Interfaces
import { IUser, IRestaurant, IAuthContext } from "./Interfaces";
import { createClient } from "@supabase/supabase-js";
import {
  IAccount,
  IBuzz,
  ICartItem,
  ICategory,
  IPopularProduct,
  IProduct,
} from "../Interfaces/generalInterfaces";
const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
const AuthContext = createContext<IAuthContext>(null!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser>(null!);
  const [restaurant, setRestaurant] = useState<IRestaurant>(null!);
  let currentUser: IUser = user;

  const updateToken = (JWT: string): void => {
    if (user?.JWT && JWT !== user?.JWT) {
      currentUser.JWT = JWT;
      setUser({ ...user, JWT });
      window.localStorage.setItem("JWT", JWT);
    }
  };

  const getToken = (): string =>
    currentUser?.JWT || window.localStorage.getItem("JWT") || "";

  const getAccountId = (): string =>
    restaurant?._id || window.localStorage.getItem("accountId") || "";

  const updateUser = (user: IUser): void => {
    setUser(user);
    currentUser = user;

    window.localStorage.setItem("id", user._id);
    window.localStorage.setItem("accountId", user.accountId);
    window.localStorage.setItem("user", user.username);
    window.localStorage.setItem("displayName", user.displayName);
    window.localStorage.setItem("profile", JSON.stringify(user.profiles));
    window.localStorage.setItem("JWT", user.JWT);
  };

  const logoutUser = (): void => {
    setUser(null!);
    setRestaurant(null!);

    window.localStorage.removeItem("id");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("profile");
    window.localStorage.removeItem("JWT");
  };

  const updateRestaurant = (restaurant: IRestaurant): void => {
    setRestaurant(restaurant);
  };
  const networkManager = new NetworkManager(
    getToken,
    logoutUser,
    updateToken,
    getAccountId,
  );

  const pusher = new Pusher(window._env_.PUSHER_APPKEY, {
    authorizer: (channel) => networkManager.pusherAuthorizerStaff(channel),
    cluster: window._env_.PUSHER_CLUSTER,
  });

  let restaurantChannel: PrivateChannel = null!;


  // if (restaurant?.channelId) {
    console.log("not here");
    const restaurantChannelId = restaurant?.channelId ?? "private-7b06d450-e1ec-11ed-b8a3-6b617911d19f";
    restaurantChannel = pusher.subscribe(restaurantChannelId) as PrivateChannel;
    restaurantChannel.authorize = (socketId) =>
      networkManager.pusherAuthorizerRestaurant(
        socketId,
        restaurantChannelId,
      );
  // }
  const [clientAccount, setClientAccount] = useState<IAccount>(null!);
  const updateAccount = (account: IAccount): void => {
    setClientAccount(account);
  };

  const [carts, setCarts] = useState<Array<ICartItem>>();
  const updateCarts = (cart: Array<ICartItem>): void => {
    setCarts(cart);
  };
  const [cateogries, setCategories] = useState<Array<ICategory>>();
  const updateCategories = (cat: Array<ICategory>): void => {
    setCategories(cat);
  };
  const [products, setProducts] = useState<Array<IProduct>>();
  const updateProducts = (prod: Array<IProduct>): void => {
    setProducts(prod);
  };
  const [buzz, setBuzz] = useState<Array<IBuzz>>();
  const updateBuzz = (buzz: Array<IBuzz>): void => {
    setBuzz(buzz);
  };
  const [popularProducts, setPopularProducts] =
    useState<Array<IPopularProduct>>();

  const updatePopularProducts = (prod: Array<IPopularProduct>): void => {
    setPopularProducts(prod);
  };

  const value = {
    user,
    restaurant,
    updateToken,
    getToken,
    getAccountId,
    updateUser,
    updateRestaurant,
    logoutUser,
    networkManager,
    pusher,
    restaurantChannel,
    updateAccount,
    clientAccount,
    carts,
    updateCarts,
    cateogries,
    updateCategories,
    products,
    updateProducts,
    buzz,
    updateBuzz,
    popularProducts,
    updatePopularProducts,
  };

  useEffect(() => {
    console.log('app start 2....');
    return () => {
      restaurantChannel && restaurantChannel.unbind();
    };
  });

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const urlPrefix = location?.pathname?.split("/");

  useEffect(() => {
    const isSuperAdmin = auth.user?.profiles.find(
      (profile) => profile === "SUPERADMIN",
    );

    if (!isSuperAdmin && urlPrefix[1] === "superadmin") {
      navigate("/home");
    }

    if (isSuperAdmin && urlPrefix[1] !== "superadmin") {
      navigate("/superadmin/home");
    }
  }, [urlPrefix]);

  useEffect(() => {
    const user_id = (auth.user && auth.user._id) != null ? auth.user._id : window.localStorage.getItem("id");
    auth.networkManager
      .getProfile(user_id)
      .then(({ success, payload, message }) => {
        if (!success) {
          auth.logoutUser();
          navigate("/login", { state: { from: location } });
          return;
        }
        const user: IUser = {
          _id: user_id,
          accountId: payload.accounts?.id,
          username: payload.user?.email ?? 'Test User',
          displayName: payload.user?.email ?? 'testuser@gmail.com',
          profiles: [payload.role],
          JWT: auth.getToken(),
        };
        auth.updateUser(user);
  
        const restaurant: IRestaurant = {
          _id: payload.accounts.id,
          _uuid: payload.accounts.uuid,
          name: payload.accounts.name,
          owner: user.displayName,
          welcomeMessage: `Welcome to ${payload.accounts.name}`,
        };
        auth.updateRestaurant(restaurant);
      });
  }, []);

  return (
    // HOTFIX FOR NEW VIEWS
    <Popover>
      {urlPrefix[1] !== "restaurant" && <TopMenu />}

      <div
        className={`${
          urlPrefix[1] !== "restaurant" && "flex p-3 bg-gray-100 h-full"
        }`}
      >
        <Outlet />
      </div>
    </Popover>
  );
};
