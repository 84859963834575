import React from 'react';
import { IBanner } from '../../Interfaces/generalInterfaces';

interface Props {
  banners?: IBanner[];
}

export const Promotions: React.FC<Props> = ({ banners = [] }) => {
  if (!banners || banners.length === 0) {
    return null; // Or you could return a message like "No promotions available"
  }

  return (
    <div className="px-5 py-4 text-left text-black">
      <h2 className="text-xl font-bold mb-4">Special Promotions</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {banners.map((banner) => (
          <div key={banner.id || banner.title} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              className="w-full h-48 object-cover"
              src={banner.image}
              alt={banner.title}
            />
            <div className="p-4">
              <h3 className="text-lg font-bold mb-2">{banner.title}</h3>
              <p className="text-gray-600">{banner.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};