import React, { useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';

import '../fonts.css';

// Hooks
import { useCookie } from '../Hooks/useCookie';

// Utils
import { generateName, plainPersistentMessages } from '../utils';
import NetworkManager from '../NetworkManager';
import {
  EVENT_MESSAGE_SENT,
  EVENT_MESSAGE_REACTION,
  messageMaxLength,
  LANGUAGE_CUSTOMER_KEY,
  DEFAULT_LANGUAGE,
  EVENT_CLEAR_TABLE,
  CLEAR,
} from '../constants';

// Components
import { BottomModal } from './Components/BottomModal';
import { QRContent } from './Components/QRContent';
import { BuzzContent } from './Components/BuzzContent';
import { HeaderNav } from './Components/HeaderNav';

// Types
import {
  IMessage,
  EMessageType,
  EBottomModalContent,
  EScreenMode,
  IHandshakeResponse,
  LanguageSetting,
  RestaurantLanguage,
  IKeyMessage,
  EntityMapper,
} from '../Interfaces/generalInterfaces';
import { LangContent } from './Components/LangContent';
import { Promotions } from './Components/Promotions';
import { MessageBottom } from './Components/MessageBottom';
import { useCustomerViewAuth } from '../Auth/CustomerViewAuth';
import Pusher from 'pusher-js';
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel';
import { AppVersion } from './Components/AppVersion';
import { SessionExpiredAlert } from './Components/SessionExpiredAlert';
let localChannel: PrivateChannel;
const toastConfig = (success: boolean) => ({
  icon: '',
  style: {
    borderRadius: '30px',
    background: success ? '#2ECC71' : '#222',
    color: '#fff',
    width: '100%',
    paddingLeft: '10px',
  },
});

export const Home = (): JSX.Element => {
  const { restaurantUUID, tableUUID, dynamicCode } = useParams();
  const [clientName, setClientName] = useCookie('clientName', '');
  const [bottomModal, setBottomModal] = useState<boolean>(false);
  const [bottomModalContent, setBottomModalContent] =
    useState<EBottomModalContent>(null!);
  const [screenMode, setScreenMode] = useState<EScreenMode>(EScreenMode.HOME);

  const [table, setTable] = useState<IHandshakeResponse>(null!);
  // State to store the tableId and restaurantId
  const [mapperIds, setMapperIds] = useState<{
    tableId: string;
    restaurantId: string;
  } | null>(null);
  const [menuURL, setMenuURL] = useState<string>(null!);
  const [chatURL, setChatURL] = useState<string>(null!);
  const [tableId, setTableId] = useState<string>(null!);
  const [restaurantId, setRestaurantId] = useState<string>(null!);
  const [error, setError] = useState<string | null>(null);
  const [badgeContent, setBadgeContent] = useState<number>(0);

  const [componentError, setComponentError] = useState<string>(null!);
  const [langList, setLangList] = useState<LanguageSetting[]>([]);
  const [languageSetting, setLanguageSetting] = useState<RestaurantLanguage>();
  const [resData, setResData] = useState(null);
  const auth = useCustomerViewAuth();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const removeQueryParam = (param) => {
      const url = new URL(window.location.href); 
      url.searchParams.delete(param); 
      window.history.replaceState({}, '', url);
      window.localStorage.setItem(CLEAR, '0');
    };
   
    
    const url = new URL(window.location.href); 
    if (url.searchParams.has('sqc')) { 
      removeQueryParam('sqc');
    }
    
    const dynamicPath = `/${restaurantUUID}/${tableUUID}/${dynamicCode}`;
    setChatURL(`/chat${dynamicPath}`);
    setMenuURL(`/menu${dynamicPath}`);

    auth.updateAccountId(restaurantUUID);
    auth.updateResourceId(tableUUID);
    auth.updateChannelId(dynamicCode);
    if (restaurantUUID && tableUUID) {
      auth.getAccount(restaurantUUID, tableUUID);
      auth.getBuzzList(restaurantUUID);
    }
    if (auth.isSessionClear) {
      setShowAlert(true);
    } else if (window.localStorage.getItem(CLEAR) && window.localStorage.getItem(CLEAR) === dynamicCode) {
      setShowAlert(true);
      console.log('cleared ...')
    }
  }, []);

  useEffect(() => {
    initializePusher();
    return () => {
      if (localChannel) localChannel.unbind();
    };
  }, []);

  const initializePusher = async () => {
    const response = await NetworkManager.clientHandshake(
      tableUUID,
      dynamicCode,
      restaurantUUID
    );

    setResData(response?.payload);
    clientHandshake(response);

    if (!restaurantUUID || !tableUUID || !response.payload.channelId) {
      return setComponentError('Error in table identifiers');
    }
    const pusher = new Pusher(window._env_.PUSHER_APPKEY, {
      authorizer: (channel) =>
        NetworkManager.pusherAuthorizer(channel, restaurantUUID, tableUUID),
      cluster: window._env_.PUSHER_CLUSTER,
    });
    localChannel = pusher.subscribe(
      response.payload.channelId
    ) as PrivateChannel;

    localChannel.bind(EVENT_CLEAR_TABLE, handleSessionClear);
  };

  const clientHandshake = async (response: any) => {
    if (!tableUUID || !dynamicCode || !restaurantUUID) {
      return setComponentError('Error in table identifiers ');
    }

    if (!response.success || !response.payload || !response.payload?.tableId) {
      const errorMessage =
        response.message || 'Unhandled initializing connection';
      return setComponentError(errorMessage);
    }
    setResData(response?.payload);

    setLanguageSetting(response?.payload.restaurantInfo?.language);
    setTable(response?.payload);
    saveScanHistory(
      response?.payload?.restaurantInfo?.name,
      response?.payload?.tableId
    );
  };
  const handleSessionClear = useCallback(
    (data) => {
      setShowAlert(true);
      auth.updateSessionClearFlag(dynamicCode, true);
       
    },
    [showAlert]
  );

  const saveScanHistory = (restaurantName: string, table: string) => {
    const item = {
      date: new Date(),
      path: window.location.pathname,
      table: table,
      name: restaurantName,
    };

    if (localStorage.getItem('scanHistory') != null) {
      const scanHistory: any = JSON.parse(
        localStorage.getItem('scanHistory') || ''
      );
      scanHistory.push(item);
      localStorage.setItem('scanHistory', JSON.stringify(scanHistory));
    } else {
      localStorage.setItem('scanHistory', JSON.stringify([item]));
    }
  };

  const getDefaultLanguage = (): LanguageSetting => {
    return DEFAULT_LANGUAGE;
  };

  const getLanguage = (code: string) => {
    const name = {
      en: 'English',
      cn: 'Chinese',
      km: 'Khmer',
      'zh-CN': 'Chinese',
    };
    return name[code];
  };

  const getLanguageCode = () => {
    let lang = getDefaultLanguage();
    if (window.localStorage.getItem(LANGUAGE_CUSTOMER_KEY)) {
      lang = window.localStorage.getItem(
        LANGUAGE_CUSTOMER_KEY
      ) as LanguageSetting;
    }
    if (window.localStorage.getItem(LANGUAGE_CUSTOMER_KEY) === 'cn') {
      lang = 'zh-CN';
    }
    return lang;
  };
  useEffect(() => {
    setLangList(['en', 'km', 'zh-CN']);
  }, []);

  const handleBottomModalContent = (modalContent: EBottomModalContent) => {
    setBottomModalContent(modalContent);
    setBottomModal(true);
  };

  const closeModal = () => {
    setBottomModal(false);
  };

  const messageTrigger = async (message: string, messageType: EMessageType) => {
    console.log('messageTrigger starting');
    closeModal();
    const newMessage: IMessage = {
      id: uuidv4(),
      message: message,
      username: clientName,
      timestamp: Date.now(),
      messageType: messageType,
    };
    if (languageSetting?.isTranslate) {
      const msg = messageType === 'CHAT' ? message : newMessage.message;
      const { data } = await NetworkManager.translateText(
        msg,
        languageSetting.targetLang
      );
      const originalMessage = newMessage.message;
      newMessage.originalMessage = originalMessage;
      newMessage.message = data;
    }
    const send = await NetworkManager.sendMessageToBackend(
      tableUUID,
      newMessage,
      table.channelId,
      EVENT_MESSAGE_SENT
    );
    localChannel.trigger(EVENT_MESSAGE_SENT, { ...newMessage });
  };

  const handleStScreenMode = (screenMode: EScreenMode) => {
    setScreenMode(screenMode);
  };

  const navBarTitle = () => {
    if (auth.clientAccount) {
      return auth.clientAccount?.type === 'RESTAURANT'
        ? `Table ${auth.clientAccount.resources[0].name}`
        : `Room ${auth.clientAccount.resources[0].name}`;
    }
  };

  const renderBottomModalContent = () => {
    switch (bottomModalContent) {
      case EBottomModalContent.QR:
        return <QRContent closeModal={closeModal} url={window.location.href} />;
      case EBottomModalContent.LANG:
        return <LangContent closeModal={closeModal} langList={langList} />;
      default:
        return null;
    }
  };
  const getTitle = () => {
    switch (bottomModalContent) {
      case EBottomModalContent.QR: {
        return 'QR Code';
      }
      case EBottomModalContent.LANG: {
        return 'Change Language';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <>
      {showAlert && <SessionExpiredAlert />}
      <HeaderNav
        setScreenMode={handleStScreenMode}
        screenMode={screenMode}
        title={navBarTitle() ?? ''}
        QRAction={() => handleBottomModalContent(EBottomModalContent.QR)}
        LangAction={() => handleBottomModalContent(EBottomModalContent.LANG)}
        language={getLanguage(getLanguageCode())}
      />

      <BuzzContent
        buzz={auth.buzz}
        closeModal={closeModal}
        messageTrigger={messageTrigger}
        language={getLanguageCode()}
        restaurantId={restaurantId}
        chatURL={chatURL}
        menuURL={menuURL}
      />

      <Promotions banners={auth.clientAccount?.banners} />

      <MessageBottom badgeContent={badgeContent} chatURL={chatURL} />

      <BottomModal
        title={getTitle()}
        show={bottomModal}
        closeModal={() => setBottomModal(false)}
      >
        {renderBottomModalContent()}
      </BottomModal>

      <AppVersion />
    </>
  );

  // Helper function to manage bottom modal content
};
