import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
// Icons

export const SessionExpiredAlert: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full h-full fixed  z-50 bg-black bg-opacity-80">
        <div className=" w-full h-full m-auto flex justify-center">
          <div className="bg-white w-[90%] py-8 px-4 rounded-xl m-auto text-center">
            <WarningIcon fontSize='large' color='warning'/> 
             
            <p className="mt-2 text-xl font-bold">Your session has been disconnected. </p>
            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
